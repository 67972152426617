export const LISTENER_PREFERENCE = [
  {
    row: "Male",
    samples: [
      {
        sampleType: "RNN",
        sampleUrl:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/ensemble-prosody-2023/rnn/a03_0066.wav",
        oracle: false
      },
      {
        sampleType: "CONV",
        sampleUrl:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/ensemble-prosody-2023/conv/a03_0066.wav",
        oracle: true
      }
    ]
  },
  {
    row: "Male",
    samples: [
      {
        sampleType: "RNN",
        sampleUrl:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/ensemble-prosody-2023/rnn/a03_00174.wav",
        oracle: true
      },
      {
        sampleType: "CONV",
        sampleUrl:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/ensemble-prosody-2023/conv/a03_00174.wav",
        oracle: false
      }
    ]
  },
  {
    row: "Female",
    samples: [
      {
        sampleType: "RNN",
        sampleUrl:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/ensemble-prosody-2023/rnn/a18_0054.wav",
        oracle: true
      },
      {
        sampleType: "CONV",
        sampleUrl:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/ensemble-prosody-2023/conv/a18_0054.wav",
        oracle: false
      }
    ]
  },
  {
    row: "Female",
    samples: [
      {
        sampleType: "RNN",
        sampleUrl:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/ensemble-prosody-2023/rnn/a18_00126.wav",
        oracle: false
      },
      {
        sampleType: "CONV",
        sampleUrl:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/ensemble-prosody-2023/conv/a18_00126.wav",
        oracle: true
      }
    ]
  }
];

export const PROSODY_SELECTION_CORRECT = [
  {
    row: "Male",
    samples: [
      {
        sampleType: "RNN",
        sampleUrl:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/ensemble-prosody-2023/rnn/a03_0084.wav",
        oracle: true
      },
      {
        sampleType: "CONV",
        sampleUrl:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/ensemble-prosody-2023/conv/a03_0084.wav",
        oracle: false
      }
    ]
  },
  {
    row: "Male",
    samples: [
      {
        sampleType: "RNN",
        sampleUrl:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/ensemble-prosody-2023/rnn/a03_0018.wav",
        oracle: false
      },
      {
        sampleType: "CONV",
        sampleUrl:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/ensemble-prosody-2023/conv/a03_0018.wav",
        oracle: true
      }
    ]
  },
  {
    row: "Male",
    samples: [
      {
        sampleType: "RNN",
        sampleUrl:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/ensemble-prosody-2023/rnn/a03_00102.wav",
        oracle: false
      },
      {
        sampleType: "CONV",
        sampleUrl:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/ensemble-prosody-2023/conv/a03_00102.wav",
        oracle: true
      }
    ]
  },
  {
    row: "Female",
    samples: [
      {
        sampleType: "RNN",
        sampleUrl:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/ensemble-prosody-2023/rnn/a18_0090.wav",
        oracle: false
      },
      {
        sampleType: "CONV",
        sampleUrl:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/ensemble-prosody-2023/conv/a18_0090.wav",
        oracle: true
      }
    ]
  },
  {
    row: "Female",
    samples: [
      {
        sampleType: "RNN",
        sampleUrl:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/ensemble-prosody-2023/rnn/a18_00168.wav",
        oracle: true
      },
      {
        sampleType: "CONV",
        sampleUrl:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/ensemble-prosody-2023/conv/a18_00168.wav",
        oracle: false
      }
    ]
  },
  {
    row: "Female",
    samples: [
      {
        sampleType: "RNN",
        sampleUrl:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/ensemble-prosody-2023/rnn/a18_00120.wav",
        oracle: true
      },
      {
        sampleType: "CONV",
        sampleUrl:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/ensemble-prosody-2023/conv/a18_00120.wav",
        oracle: false
      }
    ]
  }
];

export const PROSODY_SELECTION_INCORRECT = [
  {
    row: "Male",
    samples: [
      {
        sampleType: "RNN",
        sampleUrl:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/ensemble-prosody-2023/rnn/a03_00114.wav",
        oracle: false
      },
      {
        sampleType: "CONV",
        sampleUrl:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/ensemble-prosody-2023/conv/a03_00114.wav",
        oracle: true
      }
    ]
  },
  {
    row: "Male",
    samples: [
      {
        sampleType: "RNN",
        sampleUrl:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/ensemble-prosody-2023/rnn/a03_00144.wav",
        oracle: false
      },
      {
        sampleType: "CONV",
        sampleUrl:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/ensemble-prosody-2023/conv/a03_00144.wav",
        oracle: true
      }
    ]
  },
  {
    row: "Male",
    samples: [
      {
        sampleType: "RNN",
        sampleUrl:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/ensemble-prosody-2023/rnn/a03_00162.wav",
        oracle: true
      },
      {
        sampleType: "CONV",
        sampleUrl:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/ensemble-prosody-2023/conv/a03_00162.wav",
        oracle: false
      }
    ]
  },
  {
    row: "Female",
    samples: [
      {
        sampleType: "RNN",
        sampleUrl:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/ensemble-prosody-2023/rnn/a18_0042.wav",
        oracle: true
      },
      {
        sampleType: "CONV",
        sampleUrl:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/ensemble-prosody-2023/conv/a18_0042.wav",
        oracle: false
      }
    ]
  },
  {
    row: "Female",
    samples: [
      {
        sampleType: "RNN",
        sampleUrl:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/ensemble-prosody-2023/rnn/a18_00180.wav",
        oracle: false
      },
      {
        sampleType: "CONV",
        sampleUrl:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/ensemble-prosody-2023/conv/a18_00180.wav",
        oracle: true
      }
    ]
  },
  {
    row: "Female",
    samples: [
      {
        sampleType: "RNN",
        sampleUrl:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/ensemble-prosody-2023/rnn/a18_00174.wav",
        oracle: true
      },
      {
        sampleType: "CONV",
        sampleUrl:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/ensemble-prosody-2023/conv/a18_00174.wav",
        oracle: false
      }
    ]
  }
];
