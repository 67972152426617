import * as React from "react";
import Head from "next/head";
import { IncrementalTextPaperSummary } from "../src/pages/Research/IncrementalTextPaper";
import { PhonologicalFeaturesPaperSummary } from "../src/pages/Research/PhonologicalFeaturesPaper";
import { TCESSPaperSummary } from "../src/pages/Research/TCESSPaper";
import { EnsembleProsodyPredictionSummary } from "../src/pages/Research/EnsembleProsodyPredictionPaper";
import { Footer } from "../src/pages/Research/components";
import { FlexColumn } from "../src/components/Container";
import { H1 } from "../src/components/Typography";
import { useAsDefaultTheme } from "../src/providers/Theme";
import { useGA } from "../src/hooks/analytics/useGA";

const PAGE_TITLE = "Papercup Technologies Research";
const PAGE_DESC = "Papercup Technologies Research";

export default () => {
  useAsDefaultTheme("light");
  useGA();

  return (
    <FlexColumn
      dimensions={{ w: "mw8", pad: ["ph4", "pt4"], h: "min-vh-100" }}
      classNameOverride="center"
    >
      <Head>
        <title>{PAGE_TITLE}</title>
        <meta name="title" content={PAGE_TITLE} />
        <meta property="og:title" content={PAGE_TITLE} />
        <meta property="twitter:title" content={PAGE_TITLE} />

        <meta name="description" content={PAGE_DESC} />
        <meta property="og:description" content={PAGE_DESC} />
        <meta property="twitter:description" content={PAGE_DESC} />
      </Head>
      <H1>{PAGE_TITLE}</H1>
      <EnsembleProsodyPredictionSummary samplesLink="/samples/ensemble-prosody-prediction" />
      <TCESSPaperSummary samplesLink="/samples/temporal-control-interspeech-2021" />
      <IncrementalTextPaperSummary
        samplesLink="/samples/incremental-text-to-speech"
        paperLink="https://arxiv.org/pdf/2008.03096.pdf"
      />
      <PhonologicalFeaturesPaperSummary
        samplesLink="/samples/phonological-features-for-0-shot"
        paperLink="https://arxiv.org/pdf/2008.04107.pdf"
      />
      <div className="mt-auto"></div>
      <Footer />
    </FlexColumn>
  );
};
