export const modifiedTacotronSamples = [
  {
    sampleUrl:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/incremental-tts-2020/unidirectional-encoder/read_nv.wav",
    text: "He read a book.",
    comment:
      "The word read is pronounced to rhyme with 'need', as opposed to the colour 'red'."
  },
  {
    sampleUrl:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/incremental-tts-2020/unidirectional-encoder/secretary_nv.wav",
    text: "She was the secretary of state.",
    comment:
      "The failure mode of Sample 1 is not consistent. A worst-case scenario would consist of the sub-word secret (of secretary) being pronounced sikrət. However, what we observe is that the word secretary is pronounced sɛkrəˌtɛri suggesting that the attention (being the only component with access to future timesteps) adjusts to accomodate."
  },
  {
    sampleUrl:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/incremental-tts-2020/unidirectional-encoder/fish_nv.wav",
    text: "So long and thanks for all the fish.",
    comment:
      "Despite removing all the bi-directionality of the encoder model, the architecture is still able to capture natural features of speech. Observe in the sample the emphasis on the word all."
  }
];

export const onlineAgentTabsAndSamplesEnglish = {
  "Sample 1": {
    sentence:
      "this vehicle was occupied by three secret service agents and clifton c. garter, assistant to the vice president.",
    samples: [
      {
        modelName: "Wait Until End (WUE)   ",
        sampleUrl:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/incremental-tts-2020/english/sample_1/audio/LJ030-0095_nv_WUE.wav	",
        alignmentPlot:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/incremental-tts-2020/english/sample_1/alignments/LJ030-0095_WUE.png"
      },
      {
        modelName: "Wait 2 Steps (W2S) ",
        sampleUrl:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/incremental-tts-2020/english/sample_1/audio/LJ030-0095_nv_W2S.wav	",
        alignmentPlot:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/incremental-tts-2020/english/sample_1/alignments/LJ030-0095_W2S.png"
      },
      {
        modelName: "Wait 3 Steps (W3S) ",
        sampleUrl:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/incremental-tts-2020/english/sample_1/audio/LJ030-0095_nv_W3S.wav	",
        alignmentPlot:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/incremental-tts-2020/english/sample_1/alignments/LJ030-0095_W3S.png"
      },
      {
        modelName: "Online Agent",
        sampleUrl:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/incremental-tts-2020/english/sample_1/audio/LJ030-0095_nv_RL.wav	",
        alignmentPlot:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/incremental-tts-2020/english/sample_1/alignments/LJ030-0095_RL.png"
      }
    ]
  },
  "Sample 2": {
    sentence:
      "when he left home that morning, marina oswald, who was still in bed, suggested that he wear a jacket.",
    samples: [
      {
        modelName: "Wait Until End (WUE)",
        sampleUrl:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/incremental-tts-2020/english/sample_2/audio/LJ035-0157_nv_WUE.wav	",
        alignmentPlot:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/incremental-tts-2020/english/sample_2/alignments/LJ035-0157_WUE.png"
      },
      {
        modelName: "Wait 2 Steps (W2S)",
        sampleUrl:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/incremental-tts-2020/english/sample_2/audio/LJ035-0157_nv_W2S.wav	",
        alignmentPlot:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/incremental-tts-2020/english/sample_2/alignments/LJ035-0157_W2S.png"
      },
      {
        modelName: "Wait 3 Steps (W3S)",
        sampleUrl:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/incremental-tts-2020/english/sample_2/audio/LJ035-0157_nv_W3S.wav	",
        alignmentPlot:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/incremental-tts-2020/english/sample_2/alignments/LJ035-0157_W3S.png"
      },
      {
        modelName: "Online Agent",
        sampleUrl:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/incremental-tts-2020/english/sample_2/audio/LJ035-0157_nv_RL.wav	",
        alignmentPlot:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/incremental-tts-2020/english/sample_2/alignments/LJ035-0157_RL.png"
      }
    ]
  },
  "Sample 3": {
    sentence: "under the conditions referred to in the previous chapter,",
    samples: [
      {
        modelName: "Wait Until End (WUE)",
        sampleUrl:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/incremental-tts-2020/english/sample_3/audio/LJ002-0002_nv_WUE.wav	",
        alignmentPlot:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/incremental-tts-2020/english/sample_3/alignments/LJ002-0002_WUE.png"
      },
      {
        modelName: "Wait 2 Steps (W2S)",
        sampleUrl:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/incremental-tts-2020/english/sample_3/audio/LJ002-0002_nv_W2S.wav	",
        alignmentPlot:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/incremental-tts-2020/english/sample_3/alignments/LJ002-0002_W2S.png"
      },
      {
        modelName: "Wait 3 Steps (W3S)",
        sampleUrl:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/incremental-tts-2020/english/sample_3/audio/LJ002-0002_nv_W3S.wav	",
        alignmentPlot:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/incremental-tts-2020/english/sample_3/alignments/LJ002-0002_W3S.png"
      },
      {
        modelName: "Online Agent",
        sampleUrl:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/incremental-tts-2020/english/sample_3/audio/LJ002-0002_nv_RL.wav	",
        alignmentPlot:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/incremental-tts-2020/english/sample_3/alignments/LJ002-0002_RL.png"
      }
    ]
  }
};

export const onlineAgentTabsAndSamplesFrench = {
  "Sample 1": {
    sentence:
      "Cet inexplicable personnage avait l’air d’un professeur de mathématiques qui fait une démonstration à ses élèves.",
    samples: [
      {
        modelName: "Wait Until End (WUE)",
        sampleUrl:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/incremental-tts-2020/french/sample_1/audio/f_emph_book_s03_0209_nv_WUE.wav	",
        alignmentPlot:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/incremental-tts-2020/french/sample_1/alignments/f_emph_book_s03_0209_WUE.png"
      },
      {
        modelName: "Wait 2 Steps (W2S)",
        sampleUrl:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/incremental-tts-2020/french/sample_1/audio/f_emph_book_s03_0209_nv_W2S.wav	",
        alignmentPlot:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/incremental-tts-2020/french/sample_1/alignments/f_emph_book_s03_0209_W2S.png"
      },
      {
        modelName: "Wait 3 Steps (W3S) *",
        sampleUrl:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/incremental-tts-2020/french/sample_1/audio/f_emph_book_s03_0209_nv_W3S.wav	",
        alignmentPlot:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/incremental-tts-2020/french/sample_1/alignments/f_emph_book_s03_0209_W3S.png"
      },
      {
        modelName: "Online Agent",
        sampleUrl:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/incremental-tts-2020/french/sample_1/audio/f_emph_book_s03_0209_nv_RL.wav	",
        alignmentPlot:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/incremental-tts-2020/french/sample_1/alignments/f_emph_book_s03_0209_RL.png"
      }
    ]
  },
  "Sample 2": {
    sentence: "Je n’arrive vraiment pas à comprendre votre raisonnement.",
    samples: [
      {
        modelName: "Wait Until End (WUE)",
        sampleUrl:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/incremental-tts-2020/french/sample_2/audio/f_emph_parl_s02_0061_nv_WUE.wav	",
        alignmentPlot:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/incremental-tts-2020/french/sample_2/alignments/f_emph_parl_s02_0061_WUE.png"
      },
      {
        modelName: "Wait 2 Steps (W2S)",
        sampleUrl:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/incremental-tts-2020/french/sample_2/audio/f_emph_parl_s02_0061_nv_W2S.wav	",
        alignmentPlot:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/incremental-tts-2020/french/sample_2/alignments/f_emph_parl_s02_0061_W2S.png"
      },
      {
        modelName: "Wait 3 Steps (W3S)",
        sampleUrl:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/incremental-tts-2020/french/sample_2/audio/f_emph_parl_s02_0061_nv_W3S.wav	",
        alignmentPlot:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/incremental-tts-2020/french/sample_2/alignments/f_emph_parl_s02_0061_W3S.png"
      },
      {
        modelName: "Online Agent",
        sampleUrl:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/incremental-tts-2020/french/sample_2/audio/f_emph_parl_s02_0061_nv_RL.wav	",
        alignmentPlot:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/incremental-tts-2020/french/sample_2/alignments/f_emph_parl_s02_0061_RL.png"
      }
    ]
  },
  "Sample 3": {
    sentence:
      "Il ne parlait pas, mais il regardait, et certainement le dessin de cette contrée avec ses accidents de terrain, ses forêts, ses productions diverses, se grava dans son esprit.",
    samples: [
      {
        modelName: "Wait Until End (WUE)",
        sampleUrl:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/incremental-tts-2020/french/sample_3/audio/f_neut_book_s03_0221_nv_WUE.wav	",
        alignmentPlot:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/incremental-tts-2020/french/sample_3/alignments/f_neut_book_s03_0221_WUE.png"
      },
      {
        modelName: "Wait 2 Steps (W2S)",
        sampleUrl:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/incremental-tts-2020/french/sample_3/audio/f_neut_book_s03_0221_nv_W2S.wav	",
        alignmentPlot:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/incremental-tts-2020/french/sample_3/alignments/f_neut_book_s03_0221_W2S.png"
      },
      {
        modelName: "Wait 3 Steps (W3S)",
        sampleUrl:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/incremental-tts-2020/french/sample_3/audio/f_neut_book_s03_0221_nv_W3S.wav	",
        alignmentPlot:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/incremental-tts-2020/french/sample_3/alignments/f_neut_book_s03_0221_W3S.png"
      },
      {
        modelName: "Online Agent",
        sampleUrl:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/incremental-tts-2020/french/sample_3/audio/f_neut_book_s03_0221_nv_RL.wav	",
        alignmentPlot:
          "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/incremental-tts-2020/french/sample_3/alignments/f_neut_book_s03_0221_RL.png"
      }
    ]
  }
};
