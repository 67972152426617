export const TCESS_SAMPLES = [
  {
    "Shifted Feature": '<span class="formula">F</span><sub>0</sub>',
    "−0.5σ":
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/tcess-2021/globalcontrol/tcess/pitch/std_-0.5/a02_00_000.wav",
    "−0.25σ":
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/tcess-2021/globalcontrol/tcess/pitch/std_-0.25/a02_00_000.wav",
    "0.0σ":
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/tcess-2021/globalcontrol/tcess/pitch/std_0.0/a02_00_000.wav",
    "0.25σ":
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/tcess-2021/globalcontrol/tcess/pitch/std_0.25/a02_00_000.wav",
    "0.5σ":
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/tcess-2021/globalcontrol/tcess/pitch/std_0.5/a02_00_000.wav"
  },
  {
    "Shifted Feature": "Energy",
    "−0.5σ":
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/tcess-2021/globalcontrol/tcess/energy/std_-0.5/a02_00_000.wav",
    "−0.25σ":
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/tcess-2021/globalcontrol/tcess/energy/std_-0.25/a02_00_000.wav",
    "0.0σ":
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/tcess-2021/globalcontrol/tcess/energy/std_0.0/a02_00_000.wav",
    "0.25σ":
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/tcess-2021/globalcontrol/tcess/energy/std_0.25/a02_00_000.wav",
    "0.5σ":
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/tcess-2021/globalcontrol/tcess/energy/std_0.5/a02_00_000.wav"
  },
  {
    "Shifted Feature": "Duration",
    "−0.5σ":
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/tcess-2021/globalcontrol/tcess/duration/std_-0.5/a02_00_000.wav",
    "−0.25σ":
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/tcess-2021/globalcontrol/tcess/duration/std_-0.25/a02_00_000.wav",
    "0.0σ":
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/tcess-2021/globalcontrol/tcess/duration/std_0.0/a02_00_000.wav",
    "0.25σ":
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/tcess-2021/globalcontrol/tcess/duration/std_0.25/a02_00_000.wav",
    "0.5σ":
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/tcess-2021/globalcontrol/tcess/duration/std_0.5/a02_00_000.wav"
  }
];

export const TVAE_SAMPLES = [
  {
    "Shifted Feature": "Latent 1",
    "−2.0σ":
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/tcess-2021/globalcontrol/tvae/pitch/std_-2.0/a02_00_000.wav",
    "−1.0σ":
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/tcess-2021/globalcontrol/tvae/pitch/std_-1.0/a02_00_000.wav",
    "0.0σ":
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/tcess-2021/globalcontrol/tvae/pitch/std_0.0/a02_00_000.wav",
    "1.0σ":
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/tcess-2021/globalcontrol/tvae/pitch/std_1.0/a02_00_000.wav",
    "2.0σ":
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/tcess-2021/globalcontrol/tvae/pitch/std_2.0/a02_00_000.wav"
  },
  {
    "Shifted Feature": "Latent 2",
    "−2.0σ":
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/tcess-2021/globalcontrol/tvae/energy/std_-2.0/a02_00_000.wav",
    "−1.0σ":
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/tcess-2021/globalcontrol/tvae/energy/std_-1.0/a02_00_000.wav",
    "0.0σ":
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/tcess-2021/globalcontrol/tvae/energy/std_0.0/a02_00_000.wav",
    "1.0σ":
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/tcess-2021/globalcontrol/tvae/energy/std_1.0/a02_00_000.wav",
    "2.0σ":
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/tcess-2021/globalcontrol/tvae/energy/std_2.0/a02_00_000.wav"
  },
  {
    "Shifted Feature": "Latent 3",
    "−2.0σ":
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/tcess-2021/globalcontrol/tvae/duration/std_-2.0/a02_00_000.wav",
    "−1.0σ":
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/tcess-2021/globalcontrol/tvae/duration/std_-1.0/a02_00_000.wav",
    "0.0σ":
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/tcess-2021/globalcontrol/tvae/duration/std_0.0/a02_00_000.wav",
    "1.0σ":
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/tcess-2021/globalcontrol/tvae/duration/std_1.0/a02_00_000.wav",
    "2.0σ":
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/tcess-2021/globalcontrol/tvae/duration/std_2.0/a02_00_000.wav"
  }
];

export const TCESS_SAMPLES2 = [
  {
    "Shifted Feature": '<span class="formula">F</span><sub>0</sub>',
    "−0.5σ":
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/tcess-2021/globalcontrol/tcess/pitch/std_-0.5/a03_01_000.wav",
    "−0.25σ":
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/tcess-2021/globalcontrol/tcess/pitch/std_-0.25/a03_01_000.wav",
    "0.0σ":
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/tcess-2021/globalcontrol/tcess/pitch/std_0.0/a03_01_000.wav",
    "0.25σ":
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/tcess-2021/globalcontrol/tcess/pitch/std_0.25/a03_01_000.wav",
    "0.5σ":
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/tcess-2021/globalcontrol/tcess/pitch/std_0.5/a03_01_000.wav"
  },
  {
    "Shifted Feature": "Energy",
    "−0.5σ":
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/tcess-2021/globalcontrol/tcess/energy/std_-0.5/a03_01_000.wav",
    "−0.25σ":
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/tcess-2021/globalcontrol/tcess/energy/std_-0.25/a03_01_000.wav",
    "0.0σ":
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/tcess-2021/globalcontrol/tcess/energy/std_0.0/a03_01_000.wav",
    "0.25σ":
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/tcess-2021/globalcontrol/tcess/energy/std_0.25/a03_01_000.wav",
    "0.5σ":
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/tcess-2021/globalcontrol/tcess/energy/std_0.5/a03_01_000.wav"
  },
  {
    "Shifted Feature": "Duration",
    "−0.5σ":
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/tcess-2021/globalcontrol/tcess/duration/std_-0.5/a03_01_000.wav",
    "−0.25σ":
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/tcess-2021/globalcontrol/tcess/duration/std_-0.25/a03_01_000.wav",
    "0.0σ":
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/tcess-2021/globalcontrol/tcess/duration/std_0.0/a03_01_000.wav",
    "0.25σ":
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/tcess-2021/globalcontrol/tcess/duration/std_0.25/a03_01_000.wav",
    "0.5σ":
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/tcess-2021/globalcontrol/tcess/duration/std_0.5/a03_01_000.wav"
  }
];

export const TVAE_SAMPLES2 = [
  {
    "Shifted Feature": "Latent 1",
    "−2.0σ":
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/tcess-2021/globalcontrol/tvae/pitch/std_-2.0/a03_01_000.wav",
    "−1.0σ":
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/tcess-2021/globalcontrol/tvae/pitch/std_-1.0/a03_01_000.wav",
    "0.0σ":
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/tcess-2021/globalcontrol/tvae/pitch/std_0.0/a03_01_000.wav",
    "1.0σ":
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/tcess-2021/globalcontrol/tvae/pitch/std_1.0/a03_01_000.wav",
    "2.0σ":
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/tcess-2021/globalcontrol/tvae/pitch/std_2.0/a03_01_000.wav"
  },
  {
    "Shifted Feature": "Latent 2",
    "−2.0σ":
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/tcess-2021/globalcontrol/tvae/energy/std_-2.0/a03_01_000.wav",
    "−1.0σ":
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/tcess-2021/globalcontrol/tvae/energy/std_-1.0/a03_01_000.wav",
    "0.0σ":
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/tcess-2021/globalcontrol/tvae/energy/std_0.0/a03_01_000.wav",
    "1.0σ":
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/tcess-2021/globalcontrol/tvae/energy/std_1.0/a03_01_000.wav",
    "2.0σ":
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/tcess-2021/globalcontrol/tvae/energy/std_2.0/a03_01_000.wav"
  },
  {
    "Shifted Feature": "Latent 3",
    "−2.0σ":
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/tcess-2021/globalcontrol/tvae/duration/std_-2.0/a03_01_000.wav",
    "−1.0σ":
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/tcess-2021/globalcontrol/tvae/duration/std_-1.0/a03_01_000.wav",
    "0.0σ":
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/tcess-2021/globalcontrol/tvae/duration/std_0.0/a03_01_000.wav",
    "1.0σ":
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/tcess-2021/globalcontrol/tvae/duration/std_1.0/a03_01_000.wav",
    "2.0σ":
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/tcess-2021/globalcontrol/tvae/duration/std_2.0/a03_01_000.wav"
  }
];

export const TEMPORAL_CONTROL = [
  {
    Speaker: "Speaker",
    sentence0: "María nunca me pide <em>dinero</em> prestado",
    sentence1: "María <em>nunca</em> me pide dinero prestado",
    sentence2: "<em>María</em> nunca me pide dinero prestado"
  },
  {
    Speaker: "Male 1",
    sentence0:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/tcess-2021/emphasis/spanish/nv/a04_00_what.wav",
    sentence1:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/tcess-2021/emphasis/spanish/nv/a04_00_when.wav",
    sentence2:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/tcess-2021/emphasis/spanish/nv/a04_00_who.wav"
  },
  {
    Speaker: "Female 1",
    sentence0:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/tcess-2021/emphasis/spanish/nv/a07_00_what.wav",
    sentence1:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/tcess-2021/emphasis/spanish/nv/a07_00_when.wav",
    sentence2:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/tcess-2021/emphasis/spanish/nv/a07_00_who.wav"
  }
];
