import * as React from "react";
import Head from "next/head";

import {
  Footer,
  Samples,
  TabWithSamples,
  Section,
  PaperSummary
} from "./components";
import { useAsDefaultTheme } from "../../providers/Theme";
import * as samples from "./incrementalTextSamples";
import { Paragraph } from "../../components/Typography";
import { useGA } from "../../hooks/analytics/useGA";

const TITLE =
  "Incremental Text to Speech for Neural Sequence-to-Sequence Models using Reinforcement Learning";
const ABSTRACT =
  "Modern approaches to text to speech require the entire input character sequence to be processed before any audio is synthesised. This latency limits the suitability of such models for time-sensitive tasks like simultaneous interpretation. Interleaving the action of reading a character with that of synthesising audio reduces this latency. However, the order of this sequence of interleaved actions varies across sentences, which raises the question of how the actions should be chosen. We propose a reinforcement learning based framework to train an agent to make this decision. We compare our performance against that of deterministic, rule-based systems. Our results demonstrate that our agent successfully balances the trade-off between the latency of audio generation and the quality of synthesised audio. More broadly, we show that neural sequence-to-sequence models can be adapted to run in an incremental manner.";
const AUTHORS =
  "Devang S Ram Mohan, Raphael Lenain, Lorenzo Foglianti, Tian Huey Teh, Marlene Staib, Alexandra Torresquintero, Jiameng Gao";

export const IncrementalTextPaperSummary = ({
  samplesLink,
  paperLink
}: any) => (
  <PaperSummary
    title={TITLE}
    authors={AUTHORS}
    samplesLink={samplesLink}
    paperLink={paperLink}
  >
    {ABSTRACT}
  </PaperSummary>
);

export const IncrementalTextPaper: React.FunctionComponent = () => {
  useAsDefaultTheme("light");
  useGA();
  return (
    <div className="mw8 ph4 center mt4">
      <Head>
        <title>{TITLE}</title>
        <meta name="title" content={TITLE} />
        <meta property="og:title" content={TITLE} />
        <meta property="twitter:title" content={TITLE} />

        <meta name="description" content={ABSTRACT} />
        <meta property="og:description" content={ABSTRACT} />
        <meta property="twitter:description" content={ABSTRACT} />

        <meta name="author" content={AUTHORS} />
      </Head>
      <IncrementalTextPaperSummary />
      <Section title={"Modified Tacotron-2"}>
        <Paragraph>
          The baseline Tacotron-2 model was modified (by removing the
          bi-directional and convolutional layer in the encoder as well as the
          post-net module) in order to make it suitable for our incremental
          setting. This resulted in a slight drop in performance of the
          Tacotron-2 architecture. We provide the corresponding samples here to
          establish a baseline.
        </Paragraph>
        <Samples
          headers={["Sample", "Text", "Comments"]}
          samples={samples.modifiedTacotronSamples}
        />
      </Section>

      <Section title={"Online Agent (English)"}>
        <Paragraph>
          We present samples from our validation set of LJ Speech, comparing the
          performance of our learnt Online Agent against the benchmark models.
          In addition to the audio samples themselves, we provide the attention
          plots (along with the associated policy path of the underlying agent)
          to enable the listener to better understand the behaviour of the
          agent.
        </Paragraph>

        <TabWithSamples samples={samples.onlineAgentTabsAndSamplesEnglish} />
      </Section>
      <Section title={"Online Agent (French)"}>
        <Paragraph>
          We present samples (not formally evaluated in the paper) from our
          validation set of SIWIS, comparing the performance of our learnt
          Online Agent against the benchmark models. In addition to the audio
          samples themselves, we provide the attention plots (along with the
          associated policy path of the underlying agent) to enable the listener
          to better understand the behaviour of the agent.
          <br /> <br /> Compared to the English agent, observe that the learnt
          policy path appears to be 'further' from the prominent alignments
          diagonal. WE hypothesise that this is related to the presence of
          liasons and elisions in French.
        </Paragraph>
        <TabWithSamples samples={samples.onlineAgentTabsAndSamplesFrench} />
        <Paragraph classNameOverride="i">
          * The kink in the alignment is because we had to forcibly override the
          gate output signal and perform a READ action to ensure that the entire
          sentence is decoded under this policy
        </Paragraph>
      </Section>
      <Footer />
    </div>
  );
};
